import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { Button, InlineTextButton, IconSocialMediaFacebook, IconSocialMediaLinkedIn, ExternalLink} from '../../components';
import { LINE_ITEM_NIGHT, LINE_ITEM_DAY } from '../../util/types';
import config from '../../config';
import { ReactComponent as EmailIcon } from './email-icon.svg';
import { ReactComponent as CopyIcon } from './copy-link-icon.svg';

import css from './ListingPage.module.scss';

const SectionHeading = props => {
  const {
    priceTitle,
    formattedPrice,
    richTitle,
    category,
    hostLink,
    showContactUser,
    onContactUser,
    authorBusinessName,
    encodedURL,
    fbAppID,
    listingTitle,
  } = props;

  const unitType = config.bookingUnitType;
  const isNightly = unitType === LINE_ITEM_NIGHT;
  const isDaily = unitType === LINE_ITEM_DAY;

  const unitTranslationKey = 'ListingPage.perMonth';
  // isNightly
  //   ? 'ListingPage.perNight'
  //   : isDaily
  //   ? 'ListingPage.perDay'
  //   : 'ListingPage.perUnit';

  const Mailto = ({ email, subject = '', body = '', children }) => {
    let params = subject || body ? '?' : '';
    if (subject) params += `subject=${encodeURIComponent(subject)}`;
    if (body) params += `${subject ? '&' : ''}body=${encodeURIComponent(body)}`;

    return <a href={`mailto:${email}${params}`}>{children}</a>;
  };

  return (
    <div className={css.sectionHeading}>
      <div className={css.desktopPriceContainer}>
        <div className={css.desktopPriceValue} title={priceTitle}>
          {formattedPrice}
        </div>
        <div className={css.desktopPerUnit}>
          <FormattedMessage id={unitTranslationKey} />
        </div>
      </div>
      <div className={css.heading}>
        <h1 className={css.title}>{richTitle}</h1>
        <div className={css.author}>
          {category}
          <FormattedMessage id="ListingPage.hostedBy" values={{ name: hostLink }} />
          {authorBusinessName &&
            <span> from <strong>{authorBusinessName}</strong></span>
          }
          {showContactUser ? (
            <span className={css.contactWrapper}>
              <span className={css.separator}>•</span>
              <InlineTextButton
                rootClassName={css.contactLink}
                onClick={onContactUser}
                enforcePagePreloadFor="SignupPage"
              >
                <FormattedMessage id="ListingPage.contactUser" />
              </InlineTextButton>
            </span>
          ) : null}
        </div>
        <Mailto 
          email="" 
          subject={"Look what I found on Adlo: " + listingTitle} 
          body={encodedURL}>
            <EmailIcon className={css.emailIcon}/>
        </Mailto>
        <span className={css.separator}>•</span>
        <button 
          onClick={() => {navigator.clipboard.writeText(encodedURL)}}
          style={{border: 'none'}}>
            <CopyIcon className={css.copyIcon}/>
        </button>
        <span className={css.separator}>•</span>
        <ExternalLink 
          className={css.sharingIcon}>
            <a href = {`https://www.facebook.com/dialog/share?app_id=1447145345838598&display=popup&href=${encodedURL}&redirect_uri=${encodedURL}`} rel="noopener" target="_blank">
                <IconSocialMediaFacebook />
            </a>
        </ExternalLink>
        <span className={css.separator}>•</span>
        <ExternalLink 
          className={css.sharingIcon}>
            <a href={`https://www.linkedin.com/shareArticle?mini=true&url=${encodedURL}`} rel="noopener" target="_blank">
                <IconSocialMediaLinkedIn />
            </a>
        </ExternalLink>
      </div>
    </div>
  );
};

export default SectionHeading;
