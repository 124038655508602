import css from './ListingPage.module.scss';
import { FormattedMessage } from 'react-intl';

const SectionDiscountsMaybe = props => {
    const { publicData } = props;
    const {
        yearTerm1Discount,
        yearTerm2Discount,
        yearTerm3Discount,
        yearTerm4Discount
    } = publicData;

    const yearTerm1DiscountValid = yearTerm1Discount && !isNaN(yearTerm1Discount);
    const yearTerm2DiscountValid = yearTerm2Discount && !isNaN(yearTerm2Discount);
    const yearTerm3DiscountValid = yearTerm3Discount && !isNaN(yearTerm3Discount);
    const yearTerm4DiscountValid = yearTerm4Discount && !isNaN(yearTerm4Discount);

    const discountExists = yearTerm1DiscountValid || yearTerm2DiscountValid || yearTerm3DiscountValid || yearTerm4DiscountValid;

    if (!discountExists) {
        return null;
    }

    return (
        <div className={css.sectionDiscounts}>
            <h2 className={css.discountsTitle}>
                <FormattedMessage id="ListingPage.SectionDiscountsMaybe.title" />
            </h2>
            <div className={css.rowUnsetMarginLR}>
                {yearTerm1DiscountValid &&
                    <>
                        <div className={css.colDiscountLabel}>
                            <FormattedMessage id="ListingPage.SectionDiscountsMaybe.year1" />
                        </div>
                        <div className={css.colDiscountValue}>
                            {yearTerm1Discount}%
                        </div>
                    </>
                }
                {yearTerm2DiscountValid &&
                    <>
                        <div className={css.colDiscountLabel}>
                            <FormattedMessage id="ListingPage.SectionDiscountsMaybe.year2" />
                        </div>
                        <div className={css.colDiscountValue}>
                            {yearTerm2Discount}%
                        </div>
                    </>
                }
                {yearTerm3DiscountValid &&
                    <>
                        <div className={css.colDiscountLabel}>
                            <FormattedMessage id="ListingPage.SectionDiscountsMaybe.year3" />
                        </div>
                        <div className={css.colDiscountValue}>
                            {yearTerm3Discount}%
                        </div>
                    </>
                }
                {yearTerm4DiscountValid &&
                    <>
                        <div className={css.colDiscountLabel}>
                            <FormattedMessage id="ListingPage.SectionDiscountsMaybe.year4" />
                        </div>
                        <div className={css.colDiscountValue}>
                            {yearTerm4Discount}%
                        </div>
                    </>
                }
            </div>
        </div>
    );
};

export default SectionDiscountsMaybe;