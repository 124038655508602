import { FormattedMessage } from 'react-intl';
import css from './ListingPage.module.scss';

const SectionSupportedFileTypesMaybe = props => {
    const { publicData } = props;
    const { supported_files_types } = publicData;

    if (!supported_files_types || supported_files_types.length === 0) {
        return null;
    }

    return (
        <div className={css.sectionSupportedFileTypes}>
            <h2 className={css.supportedFileTypesTitle}>
                <FormattedMessage id="ListingPage.supportedFileTypesTitle" />
            </h2>
            <div className={css.rowUnsetMarginLR}>
                <div className={css.col12}>
                    {supported_files_types.map((fileType, i) => {
                        return (
                            <>
                                <span>{fileType}</span>
                                {i !== supported_files_types.length - 1 && <span>, </span>}
                            </>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default SectionSupportedFileTypesMaybe;
