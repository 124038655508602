import { FormattedMessage } from 'react-intl';
import css from './ListingPage.module.scss';

const SectionDimensionsMaybe = props => {
    const { publicData } = props;
    const {
        dimensionsOfSignHeight, dimensionsOfSignWidth, dimensionsOfSignUnit,
        dimensionsOfArtworkHeight, dimensionsOfArtworkWidth, dimensionsOfArtworkUnit,
    } = publicData;

    const dimensionsOfSignExists = dimensionsOfSignHeight || dimensionsOfSignWidth || dimensionsOfSignUnit;
    const dimensionsOfArtworkExists = dimensionsOfArtworkHeight || dimensionsOfArtworkWidth || dimensionsOfArtworkUnit;

    if (!dimensionsOfSignExists && !dimensionsOfArtworkExists) {
        return null;
    }

    return (
        <div className={css.sectionDimensions}>
            <h2 className={css.dimensionsTitle}>
                <FormattedMessage id="ListingPage.SectionDimensionsMaybe.dimensionsTitle" />
            </h2>
            {dimensionsOfSignExists &&
                <div className={css.rowUnsetMarginLR}>
                    <div className={css.col6}>
                        <FormattedMessage
                            id="ListingPage.SectionDimensionsMaybe.dimensionsOfSignTitle"
                            values={{ dimensionsOfSignUnit }}
                        />
                    </div>
                    <div className={css.col6}>
                        <span className={css.dimensionsValues}>
                            {dimensionsOfSignWidth && <span>{dimensionsOfSignWidth} <FormattedMessage id="ListingPage.SectionDimensionsMaybe.width" /></span>}
                            {(dimensionsOfSignWidth && dimensionsOfSignHeight) && <span> x </span>}
                            {dimensionsOfSignHeight && <span>{dimensionsOfSignHeight} <FormattedMessage id="ListingPage.SectionDimensionsMaybe.height" /></span>}
                        </span>
                    </div>
                </div>
            }
            {dimensionsOfArtworkExists &&
                <div className={css.rowUnsetMarginLR}>
                    <div className={css.col6}>
                        <FormattedMessage
                            id="ListingPage.SectionDimensionsMaybe.dimensionsOfArtworkTitle"
                            values={{ dimensionsOfArtworkUnit }}
                        />
                    </div>
                    <div className={css.col6}>
                        <span className={css.dimensionsValues}>
                            {dimensionsOfArtworkWidth && <span>{dimensionsOfArtworkWidth} <FormattedMessage id="ListingPage.SectionDimensionsMaybe.width" /></span>}
                            {(dimensionsOfArtworkWidth && dimensionsOfArtworkHeight) && <span> x </span>}
                            {dimensionsOfArtworkHeight && <span>{dimensionsOfArtworkHeight} <FormattedMessage id="ListingPage.SectionDimensionsMaybe.height" /></span>}
                        </span>
                    </div>
                </div>
            }
        </div>
    );
};

export default SectionDimensionsMaybe;
